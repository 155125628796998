import React, { useCallback, useEffect, useState } from "react";

/**Import Styles and Images */
import styles from "./index.module.scss"

import TableLayout from "../../components/TableLayout";
import { useReportUserHeader } from "../../schemas/tableHeaderSchema";
import { ReportUser as ReportUserApi } from "../../Api/ReportUser";
import { UserManagement as UserManagementApi } from "../../Api/UserManagement";

import { useSelector, useDispatch } from "react-redux";
import { resetValue } from "../../features/SearchReducer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ContentContainer from "../../components/ContentContainer";
import { Toast } from "../../hooks/useToast";


export default function ReportUser() {
    const PAGE_SIZE = 8;
    const queryClient = useQueryClient()
    const { api_token } = useSelector(state => state.profile.data)
    const { search } = useSelector(state => state.search);
    const [pageNo, setPageNo] = useState(1)

    const updateStatusMutattion = useMutation({
        mutationFn: async ({ slug, page }) => {
            queryClient.setQueryData(['report-user', page], (data) => {
                return {
                    ...data,
                    data: data.data.map(item => {
                        if (item.reportee_slug !== slug) return item
                        return {
                            ...item,
                            is_blocked: !item.is_blocked
                        }
                    })
                }
            })
            await UserManagementApi.update(slug, api_token)
        },
        onSuccess: async (data, slug) => {

        },
        onError: (err, { slug, page }) => {
            queryClient.setQueryData(['report-user', page], (data) => {
                return {
                    ...data,
                    data: data.data.map(item => {
                        if (item.reportee_slug !== slug) return item
                        return {
                            ...item,
                            is_blocked: !item.is_blocked
                        }
                    })
                }
            })
            Toast(err.message, 'error', false)
        }
    })

    const header = useReportUserHeader(pageNo, handleUpdateStatus);
    const { data, isLoading } = useQuery({
        queryKey: ['report-user', pageNo],
        queryFn: () => ReportUserApi.get(api_token, pageNo, PAGE_SIZE),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })
    const [list, setList] = useState(data);
    const dispatch = useDispatch();


    useEffect(() => {
        return () => dispatch(resetValue())
    }, [])

    useEffect(() => {
        if (!search) return setList(data);

        const array = [
            ...data.map(item => {
                if (item.reporter_name.toLowerCase().includes(search.toLowerCase()) || item.reportee_name.toLowerCase().includes(search.toLowerCase())) {
                    return item
                }
            })
        ].filter(Boolean)
        setList(array);

    }, [data, search])

    const handlePageChange = useCallback((value) => {
        setPageNo(value)
    }, [])


    function handleUpdateStatus(user_slug) {
        updateStatusMutattion.mutate(user_slug, api_token)
    }

    return (
        <ContentContainer>
            <div className={styles.ReportUser}>
                <TableLayout
                    isLoading={isLoading}
                    data={data?.data ?? []}
                    headers={header}
                    totalRecord={data?.links?.total_records ?? 0}
                    pageNo={pageNo}
                    handlePageChange={handlePageChange}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </ContentContainer>
    )
}