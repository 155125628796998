import { Axios } from "../hooks/useAxiosInstance";

export const ReportUser = (function () {


    const apis = () => ({
        get: async (api_token, page = 1, limit = 8) => await fetchRecord(api_token, page, limit),
    })

    async function fetchRecord(api_token, page, limit) {
        const url = `admin/reports/users?page=${page}&limit=${limit}`;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }


    return apis();

})()
